/* eslint-disable class-methods-use-this */
import Service from './Service';

class FinancingService extends Service {
  constructor() {
    super('financings');
  }

  getByClient(id) {
    return this.get(`client/${id}`);
  }

  getFieldsTableFinancings() {
    return [
      {
        key: 'client',
        label: 'Cliente',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap',
      },
      {
        key: 'agent',
        label: 'Agente de venda',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap',
      },
      {
        key: 'city',
        label: 'Cidade',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap',
      },
      {
        key: 'province',
        label: 'Estado',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap',
      },
      {
        key: 'actions',
        label: 'Ações',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap',
      },
    ];
  }
}

const financingService = new FinancingService();
export default financingService;
