<template>
    <div>
      <b-row class="card">
        <div class="w-100">
          <h5 class="title-card">
            <i class="fas fa-pen"></i> Filtros
          </h5>
        </div>
      </b-row>

      <b-row class="card mt-5">
        <div class="w-100">
          <h5 class="title-card">
            <i class="fas fa-file-invoice-dollar"></i> Lista de financiamentos solicitados
          </h5>
        </div>

        <div class="w-100 p-3">
          <b-table
          responsive
          bordered
          :current-page="currentPage"
          :per-page="perPage"
          :busy="loading"
          :items="financings"
          :fields="fields">
            <template #table-busy>
              <div class="text-center text-success my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Carregando...</strong>
              </div>
            </template>
            <template v-slot:cell(actions)="data">
              <b-button
              v-b-tooltip.hover
              title="Editar financiamento"
              :to="{ name: 'financing-create', params: { client_id: data.item.client_id } }"
              size="sm"
              class="mr-1"
              variant="warning">
                <i class="fas fa-pen"></i>
              </b-button><!--
              <b-button
              v-b-tooltip.hover
              title="Excluir cliente"
              :to="{ name: data.id }"
              size="sm"
              variant="danger">
                <i class="fas fa-trash"></i>
              </b-button> -->
            </template>
          </b-table>
        </div>
        <div class="w-100 py-3" v-if="totalRows > 15">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          ></b-pagination>
        </div>
      </b-row>
    </div>
</template>

<script>
import FinancingService from '@/Services/FinancingService';

export default {
  data() {
    return {
      financings: [],
      loading: false,
      fields: FinancingService.getFieldsTableFinancings(),
      totalRows: 0,
      currentPage: 1,
      perPage: 15,
      filter: {
        client: '',
        agent: '',
        project: '',
        status: '',
        date: '',
      },
    };
  },
  methods: {
    async getAll() {
      this.loading = true;
      const { data } = await FinancingService.getList();
      this.financings = data.map((item) => ({
        client_id: item.client.id,
        client: item.client.name,
        agent: item.client.agent.name,
        city: item.client.addresses[0].city.name,
        province: item.client.addresses[0].city.province.name,
      }));
      this.loading = false;
    },
  },

  mounted() {
    this.getAll();
  },
};
</script>
